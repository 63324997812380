import { useNavigate, } from 'react-router-dom';
import AccessDeniedSvg from "../../components/svg/accessDenied";

const AccessDenied = () => {

    const navigate = useNavigate();

    return (
        <div className="access-denied" style={{ height: window.innerHeight - 300, minHeight:100 }}>
            <h1>403</h1>
            <h3>Access Denied</h3>
            <p>Sorry, but you don't have permission to access this page</p>
            <p>You can go back to <span onClick={() => navigate(-2)}>previous page</span></p>
            <AccessDeniedSvg />
        </div>
    )
}

export default AccessDenied;