import { axiosBase, errorHandler } from "./axios";

const userLogin = (input: any) => {
  return axiosBase.post("/account/login", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const forgotPassword = (input: any) => {
  return axiosBase.post("/account/forgotpassword", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const forgotPasswordVerifyToken = (input: any) => {
  return axiosBase.post("/account/verifytoken", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const resetPassword = (input: any) => {
  return axiosBase.post("/account/resetpassword", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

export const authService = {
  userLogin,
  forgotPassword,
  forgotPasswordVerifyToken,
  resetPassword
};

