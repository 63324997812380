import { all, takeLatest } from 'redux-saga/effects';
import { commonSaga } from './commonSaga';
import { authSaga } from './authSaga';
import { userSaga } from './userSaga';
import { clubSaga } from './clubSaga';
import { eventSaga } from './eventSaga';
import { commonConstants } from '../actions/constants/commonConstant';
import { authConstants } from '../actions/constants/authConstant';
import { userConstants } from '../actions/constants/userConstant';
import { clubConstants } from '../actions/constants/clubConstant';
import { eventConstants } from '../actions/constants/eventConstant';

function* rootSaga() {
        yield all([
                // Common
                takeLatest(commonConstants.carouselImageRequest, commonSaga.getCarouselImages),
                takeLatest(commonConstants.pastEventListRequest, commonSaga.getPastEvents),
                takeLatest(commonConstants.activeEventListRequest, commonSaga.getActiveEvents),
                takeLatest(commonConstants.pastEventListRequest, commonSaga.getUpComingEvents),
                takeLatest(commonConstants.getImagesRequest, commonSaga.getImages),

                //Auth
                takeLatest(authConstants.userLoginRequest, authSaga.userLogin),
                takeLatest(authConstants.forgotPasswordRequest, authSaga.forgotPassword),
                takeLatest(authConstants.resendMailRequest, authSaga.resendEmail),
                takeLatest(authConstants.forgotPasswordVerifyTokenRequest, authSaga.forgotPasswordVerifyToken),
                takeLatest(authConstants.resetPasswordRequest, authSaga.resetPassword),

                //User
                takeLatest(userConstants.getAllUserRequest, userSaga.getAllUsers),
                takeLatest(userConstants.getClubManagersRequest, userSaga.getClubManagers),
                takeLatest(userConstants.createUserRequest, userSaga.createUser),
                takeLatest(userConstants.getRolesRequest, userSaga.getRoles),
                takeLatest(userConstants.getByFilterRequest, userSaga.getByFilter),
                takeLatest(userConstants.getUserRequest, userSaga.getUser),
                takeLatest(userConstants.updateUserRequest, userSaga.updateUser),
                takeLatest(userConstants.deleteUserRequest, userSaga.deleteUser),
                takeLatest(userConstants.getProfileRequest, userSaga.getProfile),
                takeLatest(userConstants.updateProfileRequest, userSaga.updateProfile),

                //clubs
                takeLatest(clubConstants.clubRegisterRequest, clubSaga.clubRegister),
                takeLatest(clubConstants.clubCreateRequest, clubSaga.clubCreate),
                takeLatest(clubConstants.verifyOtpRequest, clubSaga.clubVerifyOTP),
                takeLatest(clubConstants.resendOtpRequest, clubSaga.otpResend),
                takeLatest(clubConstants.updatePasswordRequest, clubSaga.updatePassword),
                takeLatest(clubConstants.getAllClubsRequest, clubSaga.getAllClubs),
                takeLatest(clubConstants.clubGetByFilterRequest, clubSaga.clubGetByFilter),
                takeLatest(clubConstants.getClubRequest, clubSaga.getClub),
                takeLatest(clubConstants.updateClubRequest, clubSaga.updateClub),
                takeLatest(clubConstants.deleteClubRequest, clubSaga.deleteClub),
                takeLatest(clubConstants.getClubByDomainRequest, clubSaga.getClubByDomain),

                //Event
                takeLatest(eventConstants.getAllEventsRequest, eventSaga.getAllEvents),
                takeLatest(eventConstants.getActiveEventsRequest, eventSaga.getActiveEvents),
                takeLatest(eventConstants.getUpComingEventsRequest, eventSaga.getUpComingEvents),
                takeLatest(eventConstants.getPastEventsRequest, eventSaga.getPastEvents),
                takeLatest(eventConstants.createEventRequest, eventSaga.createEvent),
                takeLatest(eventConstants.getEventRequest, eventSaga.getEvent),
                takeLatest(eventConstants.updateEventRequest, eventSaga.updateEvent),
                takeLatest(eventConstants.deleteEventRequest, eventSaga.deleteEvent),
                takeLatest(eventConstants.getUploadedImagesRequest, eventSaga.getUploadedImages),
                takeLatest(eventConstants.getApprovedImagesRequest, eventSaga.getApprovedImages),
                takeLatest(eventConstants.getRejectedImagesRequest, eventSaga.getRejectedImages),
                takeLatest(eventConstants.imageUploadRequest, eventSaga.imageUpload),
                takeLatest(eventConstants.imageApproveRequest, eventSaga.imageApprove),
                takeLatest(eventConstants.imageRejectRequest, eventSaga.imageReject),
                takeLatest(eventConstants.imageRemoveRequest, eventSaga.imageRemove),
                takeLatest(eventConstants.getByFilterRequest, eventSaga.getByFilterEvent),
        ]);
}

export default rootSaga;
