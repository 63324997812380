export const common = {
    //Roles
    adminId: "65a7ca1c0d59fb1f949160c1",
    admin: "product-admin",
    clubOwner: "club-admin",
    clubManager: "club-manager",
}

export enum Role {
    admin = "product-admin",
    clubOwner = "club-admin",
    clubManager = "club-manager",
}