import { call, put } from 'redux-saga/effects';
import { userConstants } from '../actions/constants/userConstant';
import { userService } from '../services/userService';
import { commonConstants } from '../actions/constants/commonConstant';

function* getAllUsers(): any {
    try {
        const response = yield call(userService.getAllUsers);
        yield put({ type: userConstants.getAllUserSuccess, payload: response });
    } catch (error) {
        yield put({ type: userConstants.getAllUserFailure, payload: error });
    }
}

function* getClubManagers(request: any): any {
    try {
        const response = yield call(userService.getClubManagers, request.input);
        if (response.success) {
            yield put({ type: userConstants.getClubManagersSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: userConstants.getClubManagersFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.getClubManagersFailure, payload: error });
    }
}

function* createUser(request: any): any {
    try {
        const response = yield call(userService.createUser, request.input);
        if (response.success) {
            yield put({ type: userConstants.createUserSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: userConstants.createUserFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.createUserFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getRoles(request: any): any {
    try {
        const response = yield call(userService.getRoles, request.input);
        if (response.success) {
            yield put({ type: userConstants.getRolesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: userConstants.getRolesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.getRolesFailure, payload: error });
    }
}

function* getByFilter(request: any): any {
    try {
        const response = yield call(userService.getByFilter, request.input);
        if (response.success) {
            yield put({ type: userConstants.getByFilterSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: userConstants.getByFilterFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.getByFilterFailure, payload: error });
    }
}

function* getUser(request: any): any {
    try {
        const response = yield call(userService.getUser, request.input);
        if (response.success) {
            yield put({ type: userConstants.getUserSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: userConstants.getUserFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.getUserFailure, payload: error });
    }
}

function* updateUser(request: any): any {
    try {
        const response = yield call(userService.updateUser, request.input);
        if (response.success) {
            yield put({ type: userConstants.updateUserSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: userConstants.updateUserFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.updateUserFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* deleteUser(request: any): any {
    try {
        const response = yield call(userService.deleteUser, request.input);
        if (response.success) {
            yield put({ type: userConstants.deleteUserSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: userConstants.deleteUserFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.deleteUserFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getProfile(request: any): any {
    try {
        const response = yield call(userService.getProfile, request.input);
        if (response.success) {
            yield put({ type: userConstants.getProfileSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: userConstants.getProfileFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.getProfileFailure, payload: error });
    }
}

function* updateProfile(request: any): any {
    try {
        const response = yield call(userService.updateUser, request.input);
        if (response.success) {
            yield put({ type: userConstants.updateProfileSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: userConstants.updateProfileFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: userConstants.updateProfileFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

export const userSaga = {
    getAllUsers,
    getClubManagers,
    createUser,
    getRoles,
    getByFilter,
    getUser,
    updateUser,
    deleteUser,
    getProfile,
    updateProfile
};
