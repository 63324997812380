export const getAccessToken = () => {
    const auth = localStorage.getItem("AUTHDATA")
    if (auth) {
        return JSON.parse(auth).token
    }
}

export const getUserRole = () => {
    const auth = localStorage.getItem("AUTHDATA")
    if (auth) {
        return JSON.parse(auth).role
    }
}
export const getAuthUser = () => {
    const auth = localStorage.getItem("AUTHDATA")
    if (auth) {
        return JSON.parse(auth)
    }
}

export const Capitalize = (char: any) => {
    if (typeof char !== 'string') return ''
    return char.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export const getTime = (value: any) => {
    const date = new Date(value);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const time = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    return time;
}