import Axios from 'axios';
import { getAccessToken } from './helper';
import env from './env';

export const axiosBase = Axios.create({ baseURL: env.apiUrl, });

axiosBase.interceptors.request.use(
    async config => {
        const accessToken = getAccessToken();
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const errorHandler = (error: any) => {
    if (error.response) {
        return error.response.data
    } else if (error.message) {
        return { errorMessage: error.message }
    } else {
        return { errorMessage: "Something went wrong" }
    }
}

