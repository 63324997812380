import { Navigate, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Role } from '../actions/constants/common';

interface IRouteProps {
  children?:JSX.Element;
  roles: Array<Role>;
}

export const RoleRoute: React.FC<IRouteProps> = ({ roles }) => {

  const { isLoggedIn, isLoading, authData } = useSelector((state: any) => state.authenticate);
  const requiredRoles = roles?.includes(authData.role);

  if (isLoading) {
    return <p>Checking authenticaton..</p>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: "location" }} />;
  }

  if (isLoggedIn && !requiredRoles) {
    return <Navigate to="/403" state={{ from: "location" }} />;
  }

  return <Outlet />;
}

