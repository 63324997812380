import { FC, useEffect } from "react";
import { Modal } from "antd"
import { useCycle } from "framer-motion";

interface loaderProps {
    width?: number;
    height?: number;
}

const Loader: FC<loaderProps> = () => {

    const [pathIndex, setPathIndex] = useCycle(0, 1, 2);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPathIndex();
        }, 1000);

        return () => clearTimeout(timeout);
    }, [pathIndex, setPathIndex]);

    return (
        <Modal className="svg-modal-spinner" centered mask={true} open={true} closable={false} closeIcon={false} footer={null}>
            <div className="svg-wrapper">
                <img alt="final-logo" src={require("../assets/images/bgremove.gif")}/>
            </div>
        </Modal>
    )
}

export default Loader;
