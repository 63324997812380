import { call, put } from 'redux-saga/effects';
import { eventConstants } from '../actions/constants/eventConstant';
import { commonConstants } from '../actions/constants/commonConstant';
import { eventService } from '../services/eventService';

function* getAllEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getAllEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getAllEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getAllEventsFailure, payload: error });
    }
}

function* getActiveEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getActiveEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getActiveEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getActiveEventsFailure, payload: error });
    }
}

function* getUpComingEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getUpComingEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getUpComingEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getUpComingEventsFailure, payload: error });
    }
}

function* getPastEvents(request: any): any {
    try {
        const response = yield call(eventService.getAllEvents, request.input);
        if (response.success)
            yield put({ type: eventConstants.getPastEventsSuccess, payload: response.resultObject });
        else
            yield put({ type: eventConstants.getPastEventsFailure, payload: response.message });
    } catch (error) {
        yield put({ type: eventConstants.getPastEventsFailure, payload: error });
    }
}

function* createEvent(request: any): any {
    try {
        const response = yield call(eventService.createEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.createEventSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.createEventFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.createEventFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getEvent(request: any): any {
    try {
        const response = yield call(eventService.getEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getEventSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getEventFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getEventFailure, payload: error });
    }
}

function* updateEvent(request: any): any {
    try {
        const response = yield call(eventService.updateEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.updateEventSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.updateEventFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.updateEventFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* deleteEvent(request: any): any {
    try {
        const response = yield call(eventService.deleteEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.deleteEventSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.deleteEventFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.deleteEventFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getUploadedImages(request: any): any {
    try {
        const response = yield call(eventService.getUploadedImages, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getUploadedImagesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getUploadedImagesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getUploadedImagesFailure, payload: error });
    }
}

function* getApprovedImages(request: any): any {
    try {
        const response = yield call(eventService.getApprovedImages, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getApprovedImagesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getApprovedImagesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getApprovedImagesFailure, payload: error });
    }
}

function* getRejectedImages(request: any): any {
    try {
        const response = yield call(eventService.getRejectedImages, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getRejectedImagesSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getRejectedImagesFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getRejectedImagesFailure, payload: error });
    }
}

function* imageUpload(request: any): any {
    try {
        const response = yield call(eventService.imageUpload, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageUploadSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageUploadFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageUploadFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* imageApprove(request: any): any {
    try {
        const response = yield call(eventService.imageApproveReject, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageApproveSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageApproveFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageApproveFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* imageReject(request: any): any {
    try {
        const response = yield call(eventService.imageApproveReject, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageRejectSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageRejectFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageRejectFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* imageRemove(request: any): any {
    try {
        const response = yield call(eventService.imageRemove, request.input);
        if (response.success) {
            yield put({ type: eventConstants.imageRemoveSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: eventConstants.imageRemoveFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.imageRemoveFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getByFilterEvent(request: any): any {
    try {
        const response = yield call(eventService.getByFilterEvent, request.input);
        if (response.success) {
            yield put({ type: eventConstants.getByFilterSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: eventConstants.getByFilterFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: eventConstants.getByFilterFailure, payload: error });
    }
}

export const eventSaga = {
    getAllEvents, getActiveEvents, getUpComingEvents, getPastEvents,
    createEvent, getEvent, updateEvent, deleteEvent,
    imageUpload, imageApprove, imageReject, imageRemove,
    getUploadedImages, getApprovedImages, getRejectedImages, getByFilterEvent
};
