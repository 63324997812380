import { axiosBase, errorHandler } from "./axios"

const getAllEvents = (input: any) => {
    return axiosBase.post('/events/getbyfilter', input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const createEvent = (input: any) => {
    return axiosBase.post("/events/create", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getEvent = (input: any) => {
    return axiosBase.get("/events/getevent/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const updateEvent = (input: any) => {
    return axiosBase.patch("/events/updateevent", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const deleteEvent = (input: any) => {
    return axiosBase.delete("/events/deleteevent/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getUploadedImages = (input: any) => {
    return axiosBase.post("/gallery/getuploadedimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getApprovedImages = (input: any) => {
    return axiosBase.post("/gallery/getapprovedimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getRejectedImages = (input: any) => {
    return axiosBase.post("/gallery/getrejectedimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageUpload = (input: any) => {
    return axiosBase.post("/gallery/uploadimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageApproveReject = (input: any) => {
    return axiosBase.post("/gallery/changecategory", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageRemove = (input: any) => {
    return axiosBase.delete("/gallery/deleteimages", {data :input})
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getByFilterEvent = (input: any) => {
    return axiosBase.post("/events/getbyfilter", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

export const eventService = {
    getAllEvents,
    createEvent, getEvent, updateEvent, deleteEvent,
    getUploadedImages, getApprovedImages, getRejectedImages,
    imageUpload, imageApproveReject, imageRemove, getByFilterEvent
}