import { axiosBase, errorHandler } from "./axios";

const clubCreate = (input: any) => {
  return axiosBase.post("/clubs/create", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const clubVerifyOTP = (input: any) => {
  return axiosBase.post("/clubs/verifyotp", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const resendOtp = (input: any) => {
  return axiosBase.post("/clubs/resendotp", input)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const updatePassword = (input: any) => {
  return axiosBase.post("/clubs/updatepassword", input)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const getAllClubs = () => {
  return axiosBase.get("/clubs/getallclubs")
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const clubGetByFilter = (input: any) => {
  return axiosBase.post("/clubs/getbyfilter", input)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const getClub = (input: any) => {
  return axiosBase.get("/clubs/getclub/" + input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const updateClub = (input: any) => {
  return axiosBase.patch("/clubs/updateclub", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const deleteClub = (input: any) => {
  return axiosBase.delete("/clubs/deleteclub/" + input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

const getClubByDomain = (input: any) => {
  return axiosBase.post("/clubs/getclubbydomain", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
};

export const clubService = {
  clubCreate,
  clubVerifyOTP,
  resendOtp,
  updatePassword,
  getAllClubs,
  clubGetByFilter,
  getClub,
  updateClub,
  deleteClub,
  getClubByDomain
}