import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { PrivateRoute } from './components/privateRoute';
import Spinner from './components/spinner';
import { clubActions } from './actions/clubAction';
import { useDispatch } from 'react-redux';
import Loader from './components/loader';
import { RoleRoute } from './components/roleRoute';
import { Role } from './actions/constants/common';
import AccessDenied from './pages/layout/accessDenied';

const Layout = lazy(() => import('./pages/layout/layout'));
const Home = lazy(() => import('./pages/home/home'));
const Login = lazy(() => import('./pages/account/login'));
const ForgotPassword = lazy(() => import('./pages/account/forgotPassword'));
const ResetPassword = lazy(() => import('./pages/account/resetPassword'));
const ClubList = lazy(() => import('./pages/club/clubList'));
const ClubView = lazy(() => import('./pages/club/clubView'));
const ClubEdit = lazy(() => import('./pages/club/clubView'));
const ClubCreate = lazy(() => import('./pages/club/clubCreate'));
const VerifyOtp = lazy(() => import('./pages/club/verifyOtp'));
const UpdatePassword = lazy(() => import('./pages/club/updatePassword'));
const RegistrationComplete = lazy(() => import('./pages/club/registrationComplete'));

const MyClub = lazy(() => import('./pages/club/myClub'));
const EventList = lazy(() => import('./pages/event/eventList'));
const EventView = lazy(() => import('./pages/event/eventView'));
const EventEdit = lazy(() => import('./pages/event/eventView'));
const CreateEvent = lazy(() => import('./pages/event/eventCreate'));
const UserList = lazy(() => import('./pages/user/userList'))
const UserView = lazy(() => import('./pages/user/userView'));
const UserEdit = lazy(() => import('./pages/user/userView'));
const CreateUser = lazy(() => import('./pages/user/userCreate'));
const Gallery = lazy(() => import('./pages/event/gallery'));
const PublicGallery = lazy(() => import('./pages/event/galleryView'));
const Profile = lazy(() => import('./pages/user/userProfile'));
const ChangePassword = lazy(() => import('./pages/user/changePassword'));
const CopyRight = lazy(() => import('./pages/layout/copyRight'));
const PrivacyPolicy = lazy(() => import('./pages/layout/privacyPolicy'));

const App = () => {

    const dispatch = useDispatch();
    const host = location.host.split(".");
    const protocol = window.location.protocol;
    const [domain, setDomain] = useState({
        clubDomain: (protocol === 'http:') ?
            (host.length > 1 ? host[0] : '') :
            (host.length > 2 ? host[0] : '')
    })

    const auth = useSelector((state: any) => state.authenticate);
    const status = useSelector((state: any) => state.statusReducer);
    const domainData = useSelector((state: any) => state.getClubByDomain.domainResult);

    if (domainData) {
        if (domainData.themeType)
            document.documentElement.setAttribute('color-scheme', domainData.themeType);
        if (domainData.colorScheme)
            document.documentElement.style.setProperty('--_hue', domainData.colorScheme);
    }

    useEffect(() => {
        if (host.length === 1)
            document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        if (domain.clubDomain !== '') {
            dispatch(clubActions.getClubDomain(domain))
        }
    }, [])

    useEffect(() => {
        if (status && status.isShowSuccess && !status.isShowError) {
            toast.success(status.message);
            status.isShowSuccess = false;
            status.message = ""
        }
        if (status && !status.isShowSuccess && status.isShowError) {
            toast.error(status.message);
            status.isShowError = false;
            status.message = ""
        }
    }, [status])

    useEffect(() => {
        if (auth && auth.authData) {
            if (auth.authData.themeType)
                document.documentElement.setAttribute('color-scheme', auth.authData.themeType);
            if (auth.authData.colorScheme)
                document.documentElement.style.setProperty('--_hue', auth.authData.colorScheme);
        }
    }, [auth])

    return (
        <div className="App">
            <ToastContainer theme="colored" />
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route element={<PrivateRoute />}>
                            <Route path='club/*' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                <Route element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubList /></Suspense>} />
                                </Route>
                                <Route path='create' element={<RoleRoute roles={[Role.admin]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubCreate /></Suspense>} />
                                </Route>
                                <Route path='view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubView /></Suspense>} />
                                </Route>
                                <Route path='edit' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ClubEdit /></Suspense>} />
                                </Route>
                                <Route path='myclub' element={<RoleRoute roles={[Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><MyClub /></Suspense>} />
                                </Route>
                            </Route>
                            <Route path='event/*' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                <Route index element={<Suspense fallback={<Spinner />}><EventList /></Suspense>} />
                                <Route path="create" element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><CreateEvent /></Suspense>} />
                                </Route>
                                <Route path='view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><EventView /></Suspense>} />
                                </Route>
                                <Route path='edit' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><EventEdit /></Suspense>} />
                                </Route>
                                <Route path='gallery' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><Gallery /></Suspense>} />
                                </Route>
                            </Route>
                            <Route path='user/*' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                <Route element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><UserList /> </Suspense>} />
                                </Route>
                                <Route path='create' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><CreateUser /> </Suspense>} />
                                </Route>
                                <Route path='view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><UserView /> </Suspense>} />
                                </Route>
                                <Route path='edit' element={<RoleRoute roles={[Role.admin, Role.clubOwner]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><UserEdit /> </Suspense>} />
                                </Route>
                                <Route path='profile/view' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><Profile /></Suspense>} />
                                </Route>
                                <Route path='passwordchange' element={<RoleRoute roles={[Role.admin, Role.clubOwner, Role.clubManager]} />}>
                                    <Route index element={<Suspense fallback={<Spinner />}><ChangePassword /></Suspense>} />
                                </Route>
                            </Route>
                        </Route>
                        <Route><Route path="/" element={<Suspense fallback={<Spinner />}><Home /></Suspense>} /></Route>
                        <Route><Route path="/login" element={<Suspense fallback={<Loader />}><Login /></Suspense>} /></Route>
                        {auth.isLoggedIn === true &&
                            <Route><Route path='/changepassword' element={<Suspense fallback={<Loader />}><ResetPassword /></Suspense>} /></Route>
                        }
                        {auth.isLoggedIn === false &&
                            <Route>
                                <Route path='/forgotpassword' element={<Suspense fallback={<Loader />}><ForgotPassword /></Suspense>} />
                                <Route path='/register' element={<Suspense fallback={<Loader />}> <ClubCreate /></Suspense>} />
                                <Route path='/verifyotp' element={<Suspense fallback={<Loader />}> <VerifyOtp /></Suspense>} />
                                <Route path='/updatepassword' element={<Suspense fallback={<Loader />}> <UpdatePassword /></Suspense>} />
                                <Route path='/registrationComplete' element={<Suspense fallback={<Loader />}> <RegistrationComplete /></Suspense>} />
                                <Route path='/privacypolicy' element={<Suspense fallback={<Loader />}><PrivacyPolicy /></Suspense>} />
                                <Route path="galleries/*" element={<Suspense fallback={<Loader />}><PublicGallery /></Suspense>} />
                            </Route>
                        }
                        <Route path='/copyright' element={<Suspense fallback={<Loader />}><CopyRight /></Suspense>} />
                        <Route path='/privacypolicy' element={<Suspense fallback={<Loader />}><PrivacyPolicy /></Suspense>} />
                        <Route path='/403' element={<Suspense fallback={<Loader />}><AccessDenied /></Suspense>} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </div>
    );
}

export default App;
