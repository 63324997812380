import { commonConstants } from "../actions/constants/commonConstant";

export const getCarouselImages = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.carouselImageRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case commonConstants.carouselImageSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false,
                imageData: action.payload.eventList
            }
        case commonConstants.carouselImageFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const pastEventList = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.pastEventListRequest:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case commonConstants.pastEventListSuccess:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                isError: false,
                pastEvents: action.payload
            }
        case commonConstants.pastEventListFailure:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const activeEventList = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.activeEventListRequest:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case commonConstants.activeEventListSuccess:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                isError: false,
                activeEvents: action.payload
            }
        case commonConstants.activeEventListFailure:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const upComingList = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.upComingEventListRequest:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case commonConstants.upComingEventListSuccess:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                isError: false,
                upComingEvents: action.payload
            }
        case commonConstants.upComingEventListFailure:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const toastMessage = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.showSuccesMessage:
            return {
                ...state,
                isShowMessage: true,
                isShowError: false,
                status: action.payload
            }
        case commonConstants.ShowErrorMessage:
            return {
                ...state,
                isShowMessage: false,
                isShowError: true,
                error: action.payload
            }
        default:
            return state;
    }
}

export const statusReducer = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.showSuccesMessage:
            return {
                isShowSuccess: true,
                isShowError: false,
                message: action.payload
            }
        case commonConstants.ShowErrorMessage:
            return {
                isShowSuccess: false,
                isShowError: true,
                message: action.payload
            }
        default:
            return state;
    }
}

export const getImages = (state = {}, action: any) => {
    switch (action.type) {
        case commonConstants.getImagesRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,
                imageData : []
            }
        case commonConstants.getImagesSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isGet: true,
                isError: false,
                imageData: action.payload
            }
        case commonConstants.getImagesFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload,
                imageData : []
            }
        default:
            return state;
    }
}