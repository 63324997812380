import { FC } from "react";
import { Modal, Progress, Spin } from "antd"

interface props {
    isUpload?: boolean;
    percent?: number;
    current?: number;
    total?: number;
}
const Spinner: FC<props> = ({ isUpload, percent, current, total }) => {
    return (
        < Modal
            className="spinner-container"
            centered
            mask={isUpload ? true : false}
            open={true}
            closable={false}
            closeIcon={false}
            footer={null}>
            {!isUpload ?
                <div className="spinner-inner">
                    <Spin tip={"Loading"} size="large"><div className="content" /></Spin>
                </div> :
                <div className="progress-bar">
                    <Progress percent={percent} type='circle'
                        format={(percent) => <div><h5>{percent}%</h5> {percent != 0 && <h6>{current}/{total}</h6>}</div>}
                        trailColor={'gray'} />
                </div>}
        </Modal>
    )
}

export default Spinner;