import { combineReducers } from 'redux';
import { authenticate, forgotPassword, resendEmail, verifyToken, resetPassword } from './authReducer'
import {
    userReducer, clubManager, userCreate, roles, userGetByFilter,
    getUser, updateUser, deleteUser, getProfile, updateProfile
} from './userReducer'
import { getCarouselImages, pastEventList, activeEventList, upComingList, toastMessage, statusReducer, getImages } from './commonReducer'
import {
    getAllEvents, getActiveEvents, getUpcomingEvents, getPastEvents, CreateEvent, getEvent, updateEvent, deleteEvent, imageUpload, imageApprove, imageReject,
    imageRemove, uploadedImages, approvedImages, rejectedImages, getByFilterEvent
} from './eventReducer'
import {
    clubCreate, clubVerifyOTP, resendOtp, updatePassword, clubReducer,
    clubGetByFilter, getClub, updateClub, deleteClub, clubRegister, getClubByDomain
} from './clubReducer'

const rootReducer = combineReducers({
    //Auth
    authenticate, forgotPassword, resendEmail, verifyToken, resetPassword,

    //Common
    getCarouselImages, pastEventList, activeEventList, upComingList, toastMessage, statusReducer, getImages,

    //User
    userReducer, clubManager, userCreate, roles, userGetByFilter,
    getUser, updateUser, deleteUser, getProfile, updateProfile,

    //Club
    clubCreate, clubRegister, clubVerifyOTP, resendOtp, updatePassword,
    clubReducer, clubGetByFilter, getClub, updateClub, deleteClub, getClubByDomain,

    //Event
    getAllEvents, getActiveEvents, getUpcomingEvents, getPastEvents, CreateEvent, getEvent, updateEvent, deleteEvent,
    uploadedImages, approvedImages, rejectedImages,
    imageUpload, imageApprove, imageReject, imageRemove, getByFilterEvent
});

export default rootReducer;