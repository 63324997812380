export const userConstants = {
    getAllUserRequest: "GETALL_USERS_REQUEST",
    getAllUserSuccess: "GETALL_USERS_SUCESS",
    getAllUserFailure: "GETALL_USERS_FAILURE",

    getClubManagersRequest: "GET_CLUBMANAGERS_REQUEST",
    getClubManagersSuccess: "GET_CLUBMANAGERS_SUCESS",
    getClubManagersFailure: "GET_CLUBMANAGERS_FAILURE",

    createUserRequest: "CREATE_USER_REQUEST",
    createUserSuccess: "CREATE_USER_SUCCESS",
    createUserFailure: "CREATE_USER_FAILURE",

    getRolesRequest: "GET_ROLES_REQUEST",
    getRolesSuccess: "GET_ROLES_SUCCESS",
    getRolesFailure: "GET_ROLES_FAILURE",

    getByFilterRequest: "GET_BY_FILTER_REQUEST_E",
    getByFilterSuccess: "GET_BY_FILTER_SUCCESS_E",
    getByFilterFailure: "GET_BY_FILTER_FAILURE_E",

    getProfileRequest: "GET_PROFILE_REQUEST",
    getProfileSuccess: "GET_PROFILE_SUCCESS",
    getProfileFailure: "GET_PROFILE_FAILURE",

    getUserRequest: "GET_USER_REQUEST",
    getUserSuccess: "GET_USER_SUCCESS",
    getUserFailure: "GET_USER_FAILURE",

    updateUserRequest: "UPDATE_USER_REQUEST",
    updateUserSuccess: "UPDATE_USER_SUCCESS",
    updateUserFailure: "UPDATE_USER_FAILURE",

    updateProfileRequest: "UPDATE_PROFILE_REQUEST",
    updateProfileSuccess: "UPDATE_PROFILE_SUCCESS",
    updateProfileFailure: "UPDATE_PROFILE_FAILURE",

    deleteUserRequest: "DELETE_ USER_REQUEST",
    deleteUserSuccess: "DELETE_USER_SUCCESS",
    deleteUserFailure: "DELETE_USER_FAILURE",
}


