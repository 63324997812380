import { clubConstants } from "./constants/clubConstant";

const clubRegister = (request: any) => {
  return { type: clubConstants.clubRegisterRequest, input: request };
};

const clubCreate = (request: any) => {
  return { type: clubConstants.clubCreateRequest, input: request };
};

const clubVerifyOTP = (request: any) => {
  return { type: clubConstants.verifyOtpRequest, input: request };
};

const resendotpRequest = (request: any) => {
  return { type: clubConstants.resendOtpRequest, payload: request };
};

const updatePasswordRequest = (request: any) => {
  return { type: clubConstants.updatePasswordRequest, payload: request };
};

const getAllClubs = () => {
  return { type: clubConstants.getAllClubsRequest }
}

const clubGetByFilter = (request: any) => {
  return { type: clubConstants.clubGetByFilterRequest, payload: request }
}

const getClub = (request: any) => {
  return { type: clubConstants.getClubRequest, input: request };
};

const updateClub = (request: any) => {
  return { type: clubConstants.updateClubRequest, input: request };
};

const deleteClub = (request: any) => {
  return { type: clubConstants.deleteClubRequest, input: request };
};

const getClubDomain = (request: any) => {
  return { type: clubConstants.getClubByDomainRequest, input: request };
};

export const clubActions = {
  clubRegister,
  clubCreate,
  clubVerifyOTP,
  resendotpRequest,
  updatePasswordRequest,
  getAllClubs,
  clubGetByFilter,
  getClub,
  updateClub,
  deleteClub,
  getClubDomain
};
