import { call, put } from 'redux-saga/effects';
import { commonService } from '../services/commonService';
import { commonConstants } from '../actions/constants/commonConstant';

function* getCarouselImages(request: any): any {
    try {
        const response = yield call(commonService.getCarouselImages , request.input);
        if (response.success)
            yield put({ type: commonConstants.carouselImageSuccess, payload: response.resultObject });
        else
            yield put({ type: commonConstants.carouselImageFailure, payload: response.message });
    } catch (error) {
        yield put({ type: commonConstants.carouselImageFailure, payload: error });
    }
}

function* getPastEvents(request: any): any {
    try {
        const response = yield call(commonService.getPastEvents, request.input);
        if (response.success)
            yield put({ type: commonConstants.pastEventListSuccess, payload: response.resultObject });
        else
            yield put({ type: commonConstants.pastEventListFailure, payload: response.message });
    } catch (error) {
        yield put({ type: commonConstants.pastEventListFailure, payload: error });
    }
}

function* getActiveEvents(request: any): any {
    try {
        const response = yield call(commonService.getActiveEvents, request.input);
        if (response.success)
            yield put({ type: commonConstants.activeEventListSuccess, payload: response.resultObject });
        else
            yield put({ type: commonConstants.activeEventListFailure, payload: response.message });
    } catch (error) {
        yield put({ type: commonConstants.activeEventListFailure, payload: error });
    }
}

function* getUpComingEvents(request: any): any {
    try {
        const response = yield call(commonService.getUpcomintEvents, request.input);
        if (response.success)
            yield put({ type: commonConstants.upComingEventListSuccess, payload: response.resultObject });
        else
            yield put({ type: commonConstants.upComingEventListFailure, payload: response.message });
    } catch (error) {
        yield put({ type: commonConstants.upComingEventListFailure, payload: error });
    }
}

function* getImages(request: any): any {
    try {
        const response = yield call(commonService.getImages, request.input);
        if (response.success)
            yield put({ type: commonConstants.getImagesSuccess, payload: response.resultObject });
        else
            yield put({ type: commonConstants.getImagesFailure, payload: response.message });
    } catch (error) {
        yield put({ type: commonConstants.getImagesFailure, payload: error });
    }
}

export const commonSaga = {
    getCarouselImages,
    getPastEvents,
    getActiveEvents,
    getUpComingEvents,
    getImages
}
